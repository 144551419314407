import Send from '../../trans.client.js'

export default {
  //Certificate 정보 가져오기
  getBlCertificate (formData) {
    return Send({
      url: '/trans/bl-certificate/' + formData.blNo,
      method: 'get',
      params: {
        bkgNo: formData.bkgNo,
        initFlag: formData.initFlag,
        asRefNo: formData.crtfNo || ''
      }
    })
  },
  //쉬운 Certificate 입력 가져오기
  getEasyCertificateContents (blNo) {
    return Send({
      url: '/trans/bl-certificate/' + blNo + '/easy-contents',
      method: 'get'
    })
  },
  //Certificate 인서트
  insertCerti (formData) {
    return Send({
      url: '/trans/bl-certificate/',
      method: 'post',
      data: formData
    })
  },
  //Certificate 업데이트
  updateCerti (formData) {
    return Send({
      url: '/trans/bl-certificate/' + formData.crtfNo,
      method: 'put',
      data: formData
    })
  },
  //Certificate 딜리트
  deleteCerti (crtfNo) {
    return Send({
      url: '/trans/bl-certificate/' + crtfNo,
      method: 'delete'
    })
  },
  //기존 Certificate Contents 적용
  applyCertiTemplate (crtfNo) {
    return Send({
      url: '/trans/bl-certificate/' + crtfNo + '/template',
      method: 'get'
    })
  },
  //불러오기
  getBlCall (formData) {
     return Send({
      url: '/trans/bl-certificate/bl-template',
      method: 'get',
      params: formData
    })
  }
}
